@layer base {
  .on-print {
    @apply hidden;
  }

  @media print {
    body {
      height: 297mm;
    }

    .no-print {
      @apply hidden !important;
    }

    .on-print {
      @apply block !important;
    }
  }
}

@page {
  margin-bottom: 11mm;
}
