@layer components {
  .is-style-sans {
    @apply font-sans;
  }

  .is-style-serif {
    @apply font-serif;
  }

  .has-text-align-center {
    @apply text-center;
  }

  .object-tag,
  .is-style-object-tag {
    @apply font-bold text-xs uppercase pb-[8px];
  }

  div.object-tag {
    padding-bottom: 0;
  }

  .has-white-color {
    @apply text-white;
  }

  /* FIXME this is applied on the wrong element */
  /* .core-paragraph {
    @apply pb-[30px];
  } */

  /* .core-list {
    @apply pb-[30px];
  } */

  .article-head .is-style-article-lead,
  .is-style-article-lead,
  .is-style-post-lead {
    @apply text-xl leading-normal sm:text-2xl;
  }

  .highlight {
    @apply bg-summer-green-50;
  }

  .has-drop-cap::first-letter {
    @apply float-left uppercase;
    margin: 0.05em 0.1em 0 0;
    font-size: 3.6rem;
    line-height: 3.2rem;

    @apply xl:text-6xl;
    @apply xl:leading-12;
  }

  .wysiwyg-content a {
    @apply underline cursor-pointer;
  }
}
