@layer utilities {
  .number-appearance-none {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  .flip-horizontal {
    transform: scaleX(-1);
  }

  .text-has-hover-effect {
    display: inline;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 1px;
    transition: background-size 0.3s;
  }

  .text-hover-effect {
    background-size: 100% 1px;
  }

  .text-has-hover-effect:hover {
    background-size: 100% 1px;
  }

  .image-has-hover-effect {
    transition: transform 0.5s cubic-bezier(0.395, 0.005, 0.19, 1);
  }

  .image-has-hover-effect:hover {
    transform: scale(1.025);
  }

  .image-hover-effect {
    transform: scale(1.025);
  }

  .text-meta {
    @apply text-sm;
  }

  /* Tailwind is a large stinking turd */
  .element-anchor-scroll-position {
    @apply scroll-mt-[90px] sm:scroll-mt-[120px] lg:scroll-mt-[160px];
  }
}
