@layer components {
  :focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }

  :focus:not(:focus-visible) {
    outline: 0;
  }

  :focus-visible {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }
}
