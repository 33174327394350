@layer components {
  .has-summer-green-background-color {
    @apply bg-summer-green-100;
  }

  .has-green-background-color {
    @apply bg-green-100;
  }

  .has-fun-green-background-color {
    @apply bg-good-green-100;
  }

  .has-dark-cyan-background-color {
    @apply bg-teal-100;
  }

  .has-dark-background-color {
    @apply bg-black-100;
  }

  .has-white-background-color {
    @apply bg-white;
  }

  .has-summer-green-color {
    @apply text-yellow-100;
  }

  .has-green-color {
    @apply text-green-100;
  }

  .has-fun-green-color {
    @apply text-good-green-100;
  }

  .has-dark-cyan-color {
    @apply text-teal-100;
  }

  .has-black-color {
    @apply text-black-100;
  }

  .has-dark-color {
    @apply text-black-100;
  }

  .has-white-color {
    @apply text-white;
  }

  .theme-green {
    @apply bg-green-50;
  }

  .theme-lime {
    @apply bg-summer-green-50;
  }

  .theme-white {
    @apply bg-white;
  }
}
